<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-21 12:29:21
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 21:47:57
-->
<template>
  <div class="ml_user">
    <a-table bordered :data-source="data" :columns="columns" :pagination="pagination" rowKey='id' @change="onShowSizeChange">
      <template slot="gender" slot-scope="record">
        <!-- 微信用户性别（0：未知 1：男性 2：女性） -->
        <template v-if="record.userWeChatGender == 0">未知</template>
        <template v-if="record.userWeChatGender == 1">男性</template>
        <template v-if="record.userWeChatGender == 2">女性</template>
      </template>
      <template slot="city" slot-scope="record">
        {{ record.userWeChatCountry }} {{ record.userWeChatProvince }} {{ record.userWeChatCity }}
      </template>
      <template v-slot:action>
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0">
              删除
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="1">
              删除
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>
<script>
import { getUserList } from "../utils/api";
const columns = [
  {
    title: "用户ID",
    dataIndex: "id",
    align: "center"
  },
  {
    title: "微信OpenID",
    dataIndex: "openId",
    align: "center"
  },
  {
    title: "phone",
    dataIndex: "phone",
    align: "center"
  },
  {
    title: "微信昵称",
    dataIndex: "userWeChatNickName",
    align: "center"
  },
  {
    title: "性别",
    align: "center",
    scopedSlots: { customRender: "gender" }
  },
  {
    title: "所在城市",
    align: "center",
    scopedSlots: { customRender: "city" }
  }
  // {
  //   title: "操作",
  //   key: "action",
  //   align: "center",
  //   scopedSlots: {
  //     customRender: "action"
  //   }
  // }
];
export default {
  name: "userManage",
  data() {
    return {
      data: [],
      columns,
      pagination: {
        position: "bottom",
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        current: 1,
        pageSize: 10,
        total: 50,
        showSizeChanger: true
      }
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    onShowSizeChange({ current, pageSize }) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getUserList();
    },
    getUserList() {
      const that = this;
      getUserList({
        current: that.pagination.current,
        count: that.pagination.pageSize
      }).then(res => {
        if (that.pagination.current == 1) {
          that.data = res.data;
        } else {
          that.data = that.data.concat(res.data);
        }

        that.pagination.total = res.count;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ml_user {
  padding: 10px;
}
</style>
